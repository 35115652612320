import crudService from "../services/crudService";

const path = "aggregator";

export const aggregator = {
  namespaced: true,
  state: {
    list: [],
    availableCount: 0,
  },
  actions: {
    async getList({ commit }, params) {
      await crudService.getList(path, params).then((response) => {
        commit("SET_LIST", response.data.list);
        commit("SET_AVAILABLE_COUNT", response.data.availableCount);
      });
    },
  },
  mutations: {
    SET_LIST: (state, list) => {
      state.list = list;
    },
    SET_AVAILABLE_COUNT: (state, count) => {
      state.availableCount = count;
    },
  },
  getters: {
    list: (state) => state.list,
    availableCount: (state) => state.availableCount,
  },
};
